const Exercises = [
  'jumping jacks',
  'wall sit',
  'push ups',
  'sit ups',
  'step ups',
  'squats',
  'tricept dip',
  'plank',
  'running in place',
  'lunge',
  'push up with rotation',
  'side plank'
];

export default Exercises;
import React, { useEffect, useState, useRef } from 'react';
import useSound from 'use-sound';
import Exercises from './Exercises';
import tick from './assets/tick.wav';
import bell from './assets/bell.mp3';
import horn from './assets/bike-horn-2.mp3';
import { Paper, Grid, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: '25vh',
    left: '35vw',
    width: '30vw'
  }
}));

const App = () => {
  const classes = useStyles();

  const [playTick] = useSound(tick);
  const [playHorn] = useSound(horn);
  const [playBell] = useSound(bell);

  const [running, setRunning] = useState(false);
  const [exercising, setExercising] = useState(false);
  const [step, setStep] = useState(0);

  const [time, setTime] = useState(30);
  const timer1 = useRef;

  useEffect(() => {
    if (time === 0) {
      if (exercising) {
        setTime(10);
        setExercising(false);
        playBell();
        setStep(step => step + 1);
      } else {
        setTime(30);
        setExercising(true);
        playHorn();
      }
    }
  }, [time]);

  const handleStartClick = () => {
    if (!exercising) {
      console.log('in start');
      setExercising(true);
      playTick();
      timer1.current = setInterval(() => {
        playTick();
        setTime(time => time - 1);
      }, 1000);
    }
  };

  const handleStopClick = () => {
    clearInterval(timer1.current);
    setExercising(false);
  };

  const handleResetClick = () => {
    clearInterval(timer1.current);
    setExercising(false);
    setTime(30);
    setStep(0);
  };
  // const exer = Exercises.map(exercise => )
  return (
    <>
      {/* {Exercises.map(exercise => (<h1>{exercise}</h1>))} */}
      <Paper className={classes.paper} elevation={3}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              variant='h3'
              style={{ marginBottom: '1px', color: 'green' }}
            >
              {Exercises[step]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              variant='h1'
              style={{ marginBottom: '15px', color: 'green' }}
            >
              {time}
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handleStartClick}
            >
              START
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleStopClick}
            >
              STOP
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleResetClick}
            >
              RESET
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default App;
